<template>
  <div>
    <div class="allocationInfo d-flex">
      <div>
        <div class="allocationBtn">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :class="allocation=='available' ? 'd-flex align-items-center activeStatu' : 'd-flex align-items-center'"
            @click="allocation='available'"
          >
            <div class="empselTab mr-1">
              <span class="bagdeAvailable" />
            </div>
            Available
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :class="allocation=='parttime' ? 'd-flex align-items-center activeStatu' : 'd-flex align-items-center'"
            @click="allocation='parttime'"
          >
            <div class="empselTab mr-1">
              <span class="badgePart" />
            </div>
            Part Time
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :class="allocation=='fulltime' ? 'd-flex align-items-center activeStatu' : 'd-flex align-items-center'"
            @click="allocation='fulltime'"
          >
            <div class="empselTab mr-1">
              <span class="badgeFull" />
            </div>
            Full Time
          </b-button>
        </div>
        <div class="mt-1">
          <div class="d-flex align-items-center">
            <div class="">
              <button
                class="apply-filter mr-1"
                @click="applyFilter()"
              >
                Apply
              </button>
              <button
                class="apply-filter-clear"
                @click="clearFilter()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <month-picker-input
          :no-default="false"
          :default-month="Number(month.valueOf())"
          @input="showDate"
        />

      </div>
    </div>
  </div>
</template>

<script>
import { MonthPickerInput } from 'vue-month-picker'
import { eventBus } from '@/main'
import * as moment from 'moment/moment'

export default {
  name: 'Allocation',
  components: {
    MonthPickerInput,
  },
  props: {
    month: {
      type: String,
      required: true,
    },
    year: {
      type: String,
      required: true,
    },
    allocation: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      date: {
        month: null,
        year: null,
      },
      // allocation:'',

    }
  },
  methods: {
    showDate(date) {
      this.date = date
    },
    applyFilter() {
      const data = {
        month: this.date.monthIndex ? this.date.monthIndex < 10 ? `0${this.date.monthIndex}` : this.date.monthIndex : this.month,
        year: this.date.year ? this.date.year : this.year,
        allocation: this.allocation,
      }
      eventBus.$emit('allocation-on', data)
    },
    clearFilter() {
      this.allocation = ''
      this.date.month = moment().set('month', 3)
      this.date.year = moment().set('month', 3)
      const data = {
        month: this.date.monthIndex ? this.date.monthIndex < 10 ? `0${this.date.monthIndex}` : this.date.monthIndex : this.month,
        year: this.date.year ? this.date.year : this.year,
        allocation: this.allocation,
      }
      eventBus.$emit('allocation-clear', data)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables/_variables.scss";
.apply-filter {
  background-color: $secondary-color;
  border: 1px solid $secondary-color;
  color: $white;
  padding: 6px 22px;
  border-radius: 5px;
  font-size: 2 * $rem;
  font-weight: $fw600;
  @media (max-width: map-get($mediaSizes , "xxl")) {
    font-size: 2 * $rem;
  }
  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 1.75 * $rem;
    padding: 6px 14px;
  }
  @media (max-width: map-get($mediaSizes , "lg")) {
    font-size: 1.5 * $rem;
  }
}
.apply-filter-clear {
  border: 1px solid $secondary-color;
  color: $secondary-color;
  padding: 6px 20px;
  border-radius: 5px;
  font-size: 2 * $rem;
  font-weight: $fw600;
  background-color: transparent;
  @media (max-width: map-get($mediaSizes , "xxl")) {
    font-size: 2 * $rem;
  }
  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 1.75 * $rem;
    padding: 6px 14px;
  }
  @media (max-width: map-get($mediaSizes , "lg")) {
    font-size: 1.5 * $rem;
  }
}
.activeStatu{
      background-color: $primary-color !important;
      color: $white !important;
      border-color: $primary-color !important;
    }
</style>
