<template>
  <div class="pb-5 my-team-parent">
    <div class="card-filter-section mb-50 mt-0 d-flex align-items-center justify-content-between">
      <p class="task-timesheet m-0">
        My Team
      </p>
      <Help
        v-show="$store.state.app.userManualList"
        code="MyTeam"
      />
    </div>
    <!-- Filter start -->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div style="width : 100%">
          <div class="d-flex align-items-center justify-content-start w-100">
            <p class="filter-title">
              Filters:
            </p>
            <div class="mr-75 ml-1">
              <custom-dropdown
                v-model="is_active"
                :options="isActiveList"
                :search-box="false"
                :hide-close="true"
                label="Active"
              />
            </div>
            <div>
              <custom-dropdown
                v-model="manage_by"
                :options="manage_by_users"
                label="Managed by"
              />
            </div>
            <div>
              <custom-dropdown
                v-model="reporting_to"
                :options="reporting_to_users"
                label="Reporting to"
              />
            </div>
            <div>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                text="Allocation"
                variant="flat-secondary"
                class="allocationDropdown"
                @show="closeDropDowns()"
              >
                <template
                  #button-content
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-badge
                    v-if="allocation"
                    href="#"
                    pill
                    class="badge-pill-filter allocationBadge"
                  >
                    1
                  </b-badge>
                  <span @click="allocationShow = true">Allocation</span>
                  <feather-icon
                    v-if="allocation"
                    icon="XIcon"
                    size="14"
                    @click="allocation = null"
                  />
                  <feather-icon
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                  />
                </template>
                <Allocation
                  v-if="allocationShow"
                  :month="month"
                  :year="year"
                  :allocation="allocation"
                />
              </b-dropdown>
            </div>
            <div
              v-show="primarySkills.length && secondarySkills.length"
              class="ml-50 my-team-skill-button"
            >
              [
            </div>

            <div>
              <custom-dropdown
                v-model="primarySkills"
                :options="userSkills"
                multiple
                label="Primary Skills"
              />
            </div>

            <div v-show="primarySkills.length && secondarySkills.length">

              <button
                v-b-tooltip.hover.top="skillCondition"
                class="mr-75 ml-50 my-team-skill-button"
                @click="skillCondition = skillCondition == 'AND' ? 'OR' : 'AND'"
              >
                {{ skillCondition == 'AND' ? '&&' : '| |' }}
              </button>
            </div>

            <div>
              <custom-dropdown
                v-model="secondarySkills"
                :options="userSkills"
                multiple
                label="Secondary Skills"
              />
            </div>
            <div
              v-show="primarySkills.length && secondarySkills.length"
              class="my-team-skill-button"
            >
              ]
            </div>

            <div>
              <custom-dropdown
                v-model="is_pool"
                :options="isPoolList"
                :search-box="false"
                :hide-close="true"
                label="Pool"
              />
            </div>
            <div>
              <custom-dropdown
                v-model="department_id"
                :options="departments"
                label="Department"
                multiple
              />
            </div>
            <div>
              <custom-dropdown
                v-model="division_id"
                :options="divisions"
                label="Division"
                multiple
              />
            </div>
            <div v-if="userInfo && userInfo.role == 'SA'">
              <custom-dropdown
                v-model="group_id"
                :options="groups"
                label="Groups"
              />
            </div>

            <option-drop-down v-model="perPage" />

          </div>

          <div class="d-flex align-items-center justify-content-end mt-50 w-100">
            <p
              class="filter-title "
              style="margin-right: 60px;"
            />
            <b-input-group
              class="input-group-merge search-project"
              style="min-width: 25%; max-width: 25%; width: 25%"
            >
              <b-form-input
                v-model="debouncedSearch"
                placeholder="Search by Employee Name"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="debouncedSearch"
                  icon="XIcon"
                  @click="debouncedSearch=null, search = null"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
            <div class="ml-1">

              <button
                class="apply-filter filterRedesign mr-75"
                @click="myTeamData()"
              >
                Apply Filter
              </button>
              <button
                class="apply-filter-clear filterRedesign"
                @click="clearFilter()"
              >
                Clear
              </button>
              <button
                class="export-btn filterRedesign ml-1"
                @click="myTeamData(true)"
              >
                Export
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- Filter End -->

    <!-- member table start      -->
    <div class="myteamTbl mb-1">
      <table-loader
        v-if="loading"
        :fields="fields"
      />
      <b-table
        v-else
        :items="items"
        :fields="fields"
        :no-border-collapse="noCollapse"
        responsive
      >
        <!-- table header set-->

        <template #head(allocation_by_week)>
          Efficiency <span style="font-size: 10px">(This week)</span>
        </template>
        <template #head(allocation_by_month)>
          Efficiency <span style="font-size: 10px">(This month)</span>
        </template>
        <template #head(productivity_hrms)>
          Productivity
        </template>
        <!-- Example scoped slot for select state illustrative purposes -->
        <template #cell(empselect)="data">
          <template>
            <div class="empselTab">
              <span
                class="myteamIcontxt"
                :class="data.item.total_planned_hours < 41 ? 'bagdeAvailable' : data.item.total_planned_hours > 120 ? 'badgeFull' : 'badgePart'"
              >
                {{
                  data.item.total_planned_hours
                    ? (data.item.total_planned_hours / 160 * 100).toFixed(0) > 100 ? 100 + "%" :
                      (data.item.total_planned_hours / 160 * 100).toFixed(0) + "%"
                    : 0 + "%"
                }}</span>
            </div>
          </template>
        </template>

        <template #cell(full_name)="data">

          <div class="d-flex align-items-center">
            <p>
              {{
                data.item.full_name
              }}
            </p>
            <feather-icon
              v-if="data.item.is_out_dated"
              v-b-tooltip.hover.bottom="'No allocation more than 2 months'"
              icon="AlertOctagonIcon"
              size="20"
              color="red"
              class="ml-50"
            />

          </div>
        </template>
        <template #cell(manage_by)="data">
          <div class="d-flex align-items-center">
            <p
              v-b-tooltip.hover.bottom="data.item.manage_by_user
                ? data.item.manage_by_user.full_name
                : ''
              "
            >
              {{
                data.item.manage_by_name
              }}
            </p>

          </div>
        </template>
        <template #cell(reporting_to)="data">
          <div class="d-flex align-items-center">
            <p
              v-b-tooltip.hover.bottom="data.item.users ? data.item.users.full_name : ''
              "
            >
              {{ data.item.reporting_to_name }}
            </p>
          </div>
        </template>

        <template
          #cell(primary_skill)="data"
          class="d-flex"
        >
          <div class="d-flex align-items-center teamSkills">
            <div
              v-for="(skill, index) in tagsArray(data.item.primary_skills) "
              :key="index"
            >
              <b-badge
                v-if="index < 2"
                pill
                :variant="skillBackground()"
              >
                {{
                  skill
                }}
              </b-badge>
            </div>

            <div v-if="tagsArray(data.item.primary_skills).length > 2">
              <div
                v-b-tooltip.hover.top="skills(tagsArray(data.item.primary_skills))"
                class="skillMore d-flex justify-content-center align-items-center"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="12"
                />
                {{ tagsArray(data.item.primary_skills).length - 2 }}
              </div>
            </div>

          </div>
        </template>

        <template
          #cell(secondary_skill)="data"
          class="d-flex"
        >
          <div class="d-flex align-items-center teamSkills">
            <div
              v-for="(skill, index) in tagsArray(data.item.secondary_skills) "
              :key="index"
            >
              <b-badge
                v-if="index < 2"
                pill
                :variant="skillBackground()"
              >
                {{
                  skill
                }}
              </b-badge>
            </div>

            <div v-if="tagsArray(data.item.secondary_skills).length > 2">
              <div
                v-b-tooltip.hover.top="skills(tagsArray(data.item.secondary_skills))"
                class="skillMore d-flex justify-content-center align-items-center"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="12"
                />
                {{ tagsArray(data.item.secondary_skills).length - 2 }}
              </div>
            </div>

          </div>
        </template>

        <template #cell(department)="data">
          {{
            data.item.department_name

          }}
        </template>
        <template #cell(division)="data">
          {{
            data.item.division_name
          }}
        </template>
        <template #cell(allocation_by_week)="data">
          {{
            data.item.weekly_productivity
              ? data.item.weekly_productivity + "%"
              : 0 + "%"
          }}
        </template>
        <template #cell(allocation_by_month)="data">
          {{
            data.item.monthly_productivity
              ? data.item.monthly_productivity + "%"
              : 0 + "%"
          }}
        </template>
        <template #cell(productivity_hrms)="data">
          {{
            data.item.productivity
              ? data.item.productivity + "%"
              : 0 + "%"
          }}
        </template>

        <template #cell(clockin_activity)="data">
          <skeleton-loader-vue
            v-if="isActivityLoader"
            type="ract"
            :height="18"
            animation="wave"
            class="cell-width"
          />
          <div v-else> {{
            data.item.latest_activity
          }}</div>
        </template>
        <template #cell(emptblLast)="data">

          <div class="boardDropdown mr-75 text-right">
            <b-dropdown
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <button class="edit-del-button vertical-icon-btn">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="21"
                    class="align-left edit-data-delete"
                  />
                </button>
              </template>
              <b-dropdown-item
                class="edit-delete-item"
                @click="userSummary(data.item.id)"
              >
                <span class="edit-data">View</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="edit-delete-item"
                @click="updateIsPool(data.item.id, !data.item.is_pool)"
              >
                <span class="edit-data">{{ data.item.is_pool ? 'Out to Pool' : 'Move to Pool' }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>

      <!-- pagination section -->
      <Pagination
        v-if="totalCount > 0 && !loading && !isGetTotalUserCount"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->

    </div>
    <!-- member table end  -->

    <!-- data table complete -->
    <img
      v-if="!loading && totalCount == 0"
      src="@/assets/images/nodatafound/nodata.svg"
      alt="no-data"
      class="no-data-img"
    >

  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import {
  BTable, BBadge,
} from 'bootstrap-vue'
import * as moment from 'moment/moment'
import { eventBus } from '@/main'
import debounce from 'lodash/debounce'
import SkeletonLoaderVue from 'skeleton-loader-vue'
import Allocation from '../../components/myteam/Allocation.vue'

export default {
  name: 'MyTeam',
  components: {
    customDropdown,
    BTable,
    BBadge,
    Allocation,
    SkeletonLoaderVue,
  },
  data() {
    return {
      currentPage: (this.$route.query && this.$route.query.page && Number(this.$route.query.page)) || 1,
      month: moment().format('MM'),
      year: moment().format('YYYY'),
      allocationShow: false,
      totalCount: 0,
      perPage: this.$route.query.per_page || 100,
      sortBy: null,
      sortDesc: 'desc',
      stickyHeader: true,
      noCollapse: false,
      userSkills: [],
      primarySkills: [],
      secondarySkills: [],
      reporting_to_users: [],
      manage_by_users: [],
      reporting_to: null,
      manage_by: null,
      allocation: null,
      loading: true,
      groups: [],
      group_id: null,
      search: null,
      is_active: 'active',
      modes: ['multi', 'single', 'range'],
      isPoolList: [{ label: 'All', value: 'all' },
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      is_pool: 'all',
      fields: [
        { key: 'empselect', label: '' },
        {
          key: 'full_name',
          label: 'Name',
          thClass: 'teamName',
          tdClass: 'teamName',
        },
        {
          key: 'manage_by',
          label: 'Managed By',
          thClass: 'teamName',
          tdClass: 'teamName',
        },
        {
          key: 'reporting_to',
          label: 'Reporting To',
          thClass: 'teamName',
          tdClass: 'teamName',
        },
        {
          key: 'primary_skill',
          label: 'Primary Skill(s)',
          tdClass: 'teamPrimary',
        },
        {
          key: 'secondary_skill',
          label: 'Secondary Skill(s)',
          tdClass: 'teamSecondary',
        },
        {
          key: 'department',
          label: 'Department',
          tdClass: 'teamPrimary',
        },
        {
          key: 'division',
          label: 'Division',
          tdClass: 'teamPrimary',
        },
        {
          key: 'allocation_by_week',
          label: 'Efficiency (This week)',
          tdClass: 'productivity',
        },
        {
          key: 'allocation_by_month',
          label: ' Efficiency (This month)',
          tdClass: 'productivity',
        },
        {
          key: 'productivity_hrms',
          label: 'Productivity',
          tdClass: 'productivity',
        },
        {
          key: 'clockin_activity',
          label: 'Last clock in/out activitiy',
          tdClass: 'teamActivity',
        },
        { key: 'emptblLast', label: 'action' },
      ],
      items: [],
      skillCondition: 'AND',
      pageIndex: 0,
      division_id: [],
      department_id: [],
      isGetTotalUserCount: false,
      isActivityLoader: false,
    }
  },
  computed: {
    queryData() {
      return {
        is_active:
          this.is_active,
        page: this.currentPage,
        per_page: this.perPage,
        reporting_to: this.reporting_to,
        manage_by: this.manage_by,
        primarySkills: this.primarySkills ? this.primarySkills : [],
        secondarySkills: this.secondarySkills ? this.secondarySkills : [],
        allocation: this.allocation,
        month: this.month,
        year: this.year,
        group_id: this.group_id ? this.group_id : null,
        search: this.search,
        skillCondition: this.skillCondition,
        pageIndex: this.pageIndex,
        is_pool: this.is_pool,
        department_id: this.department_id,
        division_id: this.division_id,
      }
    },
    departments() {
      return this.$store.state.app.departmentList.map(element => ({
        value: element.id,
        label: element.name,
      }))
    },
    divisions() {
      return this.$store.state.app.divisionList.map(element => ({
        value: element.id,
        label: element.name,
      }))
    },
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.myTeamData()
      }, 500),
    },

  },
  watch: {
    currentPage: {
      handler(value) {
        if (value) {
          this.myTeamData()
        }
      },
    },
    perPage() {
      if (this.currentPage == 1) {
        this.myTeamData()
      } else {
        this.currentPage = 1
      }
    },
  },

  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.setFilterValue()
    }
    this.filterFunctionCallFromMixin()

    if (this.userInfo && this.userInfo.role === 'EMP') {
      this.manage_by = this.userInfo.id
    }
    // this.setFilterValue()
    this.myTeamData()
    eventBus.$on('allocation-on', data => {
      if (data) {
        this.allocationShow = false
        this.allocation = data.allocation
        this.month = data.month
        this.year = data.year
      }
      this.currentPage = 1
      this.myTeamData()
    })
    eventBus.$on('allocation-clear', data => {
      if (data) {
        this.allocationShow = false // remove if on clear not need to close allocation filter
        this.allocation = data.allocation
        this.month = data.month
        this.year = data.year
      }
      this.currentPage = 1
      this.myTeamData()
    })
  },
  destroyed() {
    eventBus.$off('allocation-on')
    eventBus.$off('allocation-clear')
  },

  methods: {
    setFilterValue() {
      const { query } = this.$route || {} // Add a fallback to an empty object if this.$route is undefined

      if (query) {
        this.is_active = query.is_active ?? this.is_active
        this.perPage = query.perPage ?? this.perPage
        this.currentPage = Number(this.$route.query.page) ?? this.currentPage
        this.reporting_to = query.reporting_to ?? this.reporting_to
        this.manage_by = query.manage_by ?? this.manage_by
        this.skillCondition = query.skillCondition ?? this.skillCondition
        this.secondarySkills = Array.isArray(query.secondarySkills) ? query.secondarySkills : query.secondarySkills ? [query.secondarySkills] : this.secondarySkills
        this.primarySkills = Array.isArray(query.primarySkills) ? query.primarySkills : query.primarySkills ? [query.primarySkills] : this.primarySkills
        this.allocation = query.allocation ?? this.allocation
        this.month = query.month ?? this.month
        this.year = query.year ?? this.year
        this.group_id = query.group_id ?? this.group_id
        this.search = query.search ?? this.search
        this.pageIndex = query.pageIndex
        this.is_pool = query.is_pool ?? this.is_pool
        this.division_id = Array.isArray(query.division_id) ? query.division_id : query.division_id ? [query.division_id] : this.division_id
        this.department_id = Array.isArray(query.department_id) ? query.department_id : query.department_id ? [query.department_id] : this.department_id
      }
    },

    closeDropDowns() {
      eventBus.$emit('closeCustomDropDown')
    },
    /*  filter data */
    async filterFunctionCallFromMixin() {
      /**  role super admin grouplist & manageby list , reporting , primaryskill , userlist  common api calling  */
      if (this.userInfo && this.userInfo.role == 'SA') {
        if (this.$store.state.app.groupList.length == 0) {
          this.groups = await this.getGroupData()
        } else {
          this.groups = this.$store.state.app.groupList
        }
      }
      if (this.$store.state.app.manageByUsersList.length == 0) {
        this.manage_by_users = await this.manageByUserList()
      } else {
        this.manage_by_users = this.$store.state.app.manageByUsersList
      }

      if (this.$store.state.app.reportingUserList.length == 0) {
        this.reporting_to_users = await this.reportingtoUserList()
      } else {
        this.reporting_to_users = this.$store.state.app.reportingUserList
      }

      if (this.$store.state.app.userSkills.length == 0) {
        this.userSkills = await this.getPrimarySkillData()
      } else {
        this.userSkills = this.$store.state.app.userSkills
      }

      if (this.$store.state.app.departmentList.length == 0) {
        await this.departmentList()
      }

      if (this.$store.state.app.divisionList.length == 0) {
        await this.divisionList()
      }
    },

    skills(data) {
      return data.slice(2).map(element => element).join(' , ')
    },

    userSummary(id) {
      this.pageIndex = window.scrollY
      this.$router.push({
        name: 'MyTeamDetails',
        params: {
          id,
        },
        query: this.queryData,
      })
    },
    clearFilter() {
      this.group_id = null
      this.primarySkills = []
      this.secondarySkills = []
      if (this.userInfo && this.userInfo.role === 'EMP') {
        this.manage_by = this.userInfo.id
      } else {
        this.manage_by = null
      }
      this.reporting_to = null
      this.allocation = null
      this.currentPage = 1
      this.search = null
      this.perPage = 100
      this.month = moment().format('MM')
      this.year = moment().format('YYYY')
      this.is_active = 'active'
      this.skillCondition = 'AND'
      this.pageIndex = 0
      this.is_pool = 'all'
      this.department_id = []
      this.division_id = []
      this.myTeamData()
    },
    async updateIsPool(id, type) {
      const title = type ? 'Are you sure you want to move this user to pool?' : 'Are you sure you want to out this user to pool?'
      const buttonLabel = 'Yes'
      const confirmation = await this.conformationAlert(false, title, buttonLabel)
      if (confirmation.isConfirmed === true) {
        this.loading = true
        const input = {
          is_pool: type,
        }
        const response = await this.getHTTPPostResponse(
          `admin/master/user/update-is-pool/${id}`,
          input,
          true,
        )
        if (response && response.data) {
          this.myTeamData()
        }
      }
    },
    /* my team list get or export */
    async myTeamData(exportPdf = false) {
      const input = this.commonPayloadObject() // Generate the common payload object

      if (exportPdf) {
        const url = this.myTeamExportUrl(input) // Generate the export URL
        await this.downloadPdf(url) // Download the PDF if export is requested
      } else {
        this.routeQueryUpdate(this.queryData)

        await this.fetchTeamData(input) // Fetch the team data otherwise
      }
    },

    /* comment payload */
    commonPayloadObject() {
      return {
        is_active: this.is_active && this.is_active !== 'all_data' ? (this.is_active === 'active') : '',
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: this.sortBy,
        sort_order: this.sortDesc ? 'desc' : 'asc',
        reporting_to: this.reporting_to,
        manage_by: this.manage_by,
        primary_skill_ids: this.primarySkills || [],
        secondary_skill_ids: this.secondarySkills || [],
        allocation: this.allocation,
        month: this.month,
        year: this.year,
        group_id: this.group_id || null,
        search: this.search,
        skill_condition: this.skillCondition,
        is_pool: this.is_pool != 'all' ? this.is_pool == 'yes' : null,
        department_id: this.department_id || [],
        division_id: this.division_id || [],
      }
    },

    /* prepare export url */
    myTeamExportUrl(input) {
      let URL = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/admin/master/my-team/export-team?user_id=${this.userInfo.id}&role=${this.userInfo.role}&is_active=${input.is_active}`

      const queryParams = ['reporting_to', 'manage_by', 'month', 'allocation', 'year', 'group_id', 'search', 'skill_condition', 'is_pool']
      queryParams.forEach(param => {
        if (param == 'is_pool' && this.is_pool != 'all') {
          URL += `&${param}=${input[param]}`
        } else if (input[param]) {
          URL += `&${param}=${input[param]}`
        }
      })

      if (input.primary_skill_ids.length) {
        input.primary_skill_ids.forEach(element => {
          URL += `&primary_skill_ids[]=${element}`
        })
      }

      if (input.secondary_skill_ids.length) {
        input.secondary_skill_ids.forEach(element => {
          URL += `&secondary_skill_ids[]=${element}`
        })
      }

      if (input.department_id.length) {
        input.department_id.forEach(element => {
          URL += `&department_id[]=${element}`
        })
      }

      if (input.division_id.length) {
        input.division_id.forEach(element => {
          URL += `&division_id[]=${element}`
        })
      }
      return URL
    },

    async downloadPdf(url) {
      await this.$axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then(() => {
        location.href = url // Trigger the download by setting the window location to the URL
      })
    },

    async fetchTeamData(input) {
      this.loading = true

      const response = await this.getHTTPPostResponse('admin/master/my-team/list-sql', input, false)

      if (response && response.data) {
        this.items = response.data.users // Store the fetched data in the items array

        if (this.items.length > 0) {
          // If there are items, fetch additional data like the latest activity and total user count
          this.getLatestActivity()
          this.getTotalUserCount()
        } else {
          this.totalCount = 0
        }
      }

      this.loading = false
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.pageIndex // Scroll the page to the saved position
      })
      setTimeout(() => {
        this.pageIndex = 0 // Reset the page index after a short delay
      }, 1000)
    },

    /* get items variable users  latest activity  */
    async getLatestActivity() {
      this.isActivityLoader = true
      const input = {
        user_ids: this.items.map(user => user.id),
      }

      const response = await this.getHTTPPostResponse('admin/master/my-team/user-latest-activity', input, false)

      if (response && response.data && Array.isArray(response.data.users)) {
        // If the response contains data, update the latest activity for each user
        response.data.users.forEach(element => {
          const user = this.items.find(e => e.id === element.id)
          if (user) {
            this.$set(user, 'latest_activity', element.latest_activity)
          }
        })
      }

      this.isActivityLoader = false
    },

    /* for pagination get total number of user */
    async getTotalUserCount() {
      this.isGetTotalUserCount = true
      const input = this.commonPayloadObject() // Create the payload object for the request

      const response = await this.getHTTPPostResponse('admin/master/my-team/total-user-count', input, false)

      if (response && response.data) {
        this.totalCount = response.data.count // Store the total user count
      }

      this.isGetTotalUserCount = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/component-css/masterSettingFilter.scss";
</style>
